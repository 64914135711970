<script>
import LogoSvg from "@/components/Landing/LogoSvg.vue";
import router from "../../../router";

export default {
	components: {LogoSvg},

	data() {
		return {
			email: '',
			username: '',
		};
	},

	methods: {
		goToEmailVerification() {
			router.push('/email-verification')
		}
	},

	mounted() {
		this.email = this.$route.params.email;
		this.username = this.$route.params.username;
	},
}
</script>

<template>
	<div class="account-pages my-5 pt-sm-5">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-md-8 col-lg-6 col-xl-5">
					<div class="text-center mb-4">
						<router-link to="/" class="auth-logo mb-5 d-block">
							<LogoSvg/>
						</router-link>
						<h4>Almost There!</h4>
					</div>
					<div class="card">
						<div class="card-body p-4">
							<div class="p-3">
								<p>Hi {{ username }},</p>
								<p>To finish setting up your account, please confirm your email address. Just click the
									link we sent to <span style="font-weight:bold;">{{ email }}.</span></p>
								<p>Didn't get the email? Check your spam folder or
									<button
										class="btn btn-primary btn-block"
										style="margin-top:20px;"
										@click="goToEmailVerification"
									>Click here</button>
								</p>
							</div>
						</div>
					</div>

					<div class="mt-5 text-center">
						<p>
							{{ $t('register.form.signintext') }}
							<router-link
								tag="a"
								to="/login"
								class="font-weight-medium text-primary"
							>{{ $t('register.form.signinlink') }}
							</router-link>
						</p>
						<p>
							© {{ new Date().getFullYear() }}
							<i
								class="mdi mdi-heart text-danger"
							></i>
							Romeo
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">

</style>